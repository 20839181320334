// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-shorts-jsx": () => import("./../../../src/pages/shorts.jsx" /* webpackChunkName: "component---src-pages-shorts-jsx" */),
  "component---src-pages-social-media-jsx": () => import("./../../../src/pages/social-media.jsx" /* webpackChunkName: "component---src-pages-social-media-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-template-jsx": () => import("./../../../src/templates/blogTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */)
}

